import React, {useState, useEffect} from 'react';
import Ds from "./ds";
function App() {
  const [educations, setEducations] = useState([]);
  const [educationsBuild, setEducationsBuild] = useState([]);
  const [experiences, setExperiences] = useState([]);
  const [experiencesBuild, setExperiencesBuild] = useState([]);
  const [projects, setProjects] = useState([]);
  const [projectsBuild, setProjectsBuild] = useState([]);
  const [maxEl, setMaxEl] = useState([0,0,0]);


  useEffect(() => {
    fetch('https://rueger.berlin/json/getEducations.json')
        .then(response => response.json())
        .then(data => setEducations(data));
    fetch('https://rueger.berlin/json/getExperiences.json')
        .then(response => response.json())
        .then(data => setExperiences(data));
    fetch('https://rueger.berlin/json/getProjects.json')
        .then(response => response.json())
        .then(data => setProjects(data));

  }, []);

  function getLength(array){
    let maxCount = 0;


    array.forEach(activity => {
      let charCount = 0;
      if(activity.activities) charCount = activity.activities.length;
      if(activity.short_description) charCount = activity.short_description.length;
      if(activity.notes) charCount = activity.notes.length;


      maxCount = Math.max(maxCount, charCount);
    });

    return maxCount;
  }

  useEffect(() => {

    buildEducationTable();
    buildExperiencesTable();
    buildProjectsTable();
  }, [maxEl]);
  useEffect(() => {
    setMaxEl([
    getLength(educations),
    getLength(experiences),
    getLength(projects)]);

  }, [educations, experiences, projects]);

  function buildEducationTable() {
    let tmp = [];
    console.log(maxEl);
    for (let i = 0; i < educations.length; i++) {
      tmp.push(
          <div className="col-md-3" style={{marginBottom: "2vh"}}>
            <div className="card p-3" style={{minHeight: maxEl[0]/3+"vh"}}>
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-row align-items-center">
                  <div className="icon"><img src={"logos/" + educations[i].logo} className="rounded"
                                             width="48px"/></div>
                  <div className="ms-2 c-details">
                    <h6 className="mb-0">{educations[i].company}</h6>
                    <span>{educations[i].jfrom.substr(0, 4)} - {educations[i].jtill.substr(0, 4)}</span>
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <h4 className="heading">{educations[i].position}</h4>
                {educations[i].notes}
              </div>
            </div>
          </div>);
    }
    setEducationsBuild(tmp);
  }


  function buildExperiencesTable() {
    let tmp = [];
    for (let i = 0; i < experiences.length; i++) {
      tmp.push(
          <div className="col-md-3" style={{marginBottom: "2vh"}}>
            <div className="card p-3" style={{minHeight: maxEl[1]/4+"vh"}}>
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-row align-items-center">
                  <div className="icon"><img src={"logos/" + experiences[i].logo} className="rounded"
                                             width="48px"/></div>
                  <div className="ms-2 c-details">
                    <h6 className="mb-0">{experiences[i].company}</h6>
                    <span>{experiences[i].jfrom.substr(0, 7)} - {experiences[i].jtill === "0000-00-00" ? "heute" : experiences[i].jtill.substr(0, 7)}</span>
                  </div>
                </div>
                {experiences[i].employee.length > 1 ?
                    <div className="badge"><span>{experiences[i].employee} Mitarbeiter</span></div> : ""}
              </div>
              <div className="mt-3">
                <h4 className="heading">{experiences[i].position}</h4>
                {experiences[i].activities}
              </div>
            </div>
          </div>);
    }
    setExperiencesBuild(tmp);
  }

  function buildProjectsTable() {
    let tmp = [];
    for (let i = 0; i < projects.length; i++) {
      let linkName = projects[i].name
      if(projects[i].href){
        linkName =  <a  target="_blank" href={projects[i].href}>{projects[i].name}</a>
      }
      tmp.push(
          <div className="col-md-3" style={{marginBottom: "2vh"}}>
            <div className="card p-3" style={{minHeight: maxEl[2]/3+"vh"}}>
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-row align-items-center">

                  <div className="ms-2 c-details">
                    <h6 className="mb-0">{linkName}</h6> <span>{projects[i].environment}</span>
                  </div>
                </div>
                <div className="badge"><span>{projects[i].duration}</span></div>
              </div>
              <div className="mt-3">
                <div><img src={"projects/" + projects[i].cover} className="rounded" width="100%"
                          height="100%"/></div>
                <div> {projects[i].short_description}</div>
              </div>
            </div>
          </div>);
    }
    setProjectsBuild(tmp);
  }


  function ImpressumKontakt() {
    alert("Stefan Rüger\n" +
        "Marie-Curie-Allee 17\n" +

        "10315 Berlin\nUSt-IdNr: DE347996327\n01602615655\ninfo@rueger.berlin")
  }


  let minWith = "100%";
  let minHeight = "100vh";
  return (
      <div className="container-fluid" style={{padding: "0px",   maxWidth:"100%"}}>




        <div   style={{ maxWidth:minWith,  minHeight: minHeight, minWidth: minWith, backgroundColor: "#ffffff"}} className="logo-box"></div>




        <div style={{maxWidth:minWith,  minHeight: minHeight, minWidth: minWith, backgroundColor: "#f0f0f0"}}>

          <div className="" style={{paddingTop: "20px"}}>
            <figure className="text-center">
              <h3>Arbeitserfahrung</h3></figure>
            <div className="d-inline-flex p-3">
              <div className="row">{experiencesBuild}  </div>
            </div>
          </div>
          <div className="" style={{paddingTop: "20px"}}>
            <figure className="text-center">
              <h3>Ausbildung</h3></figure>
            <div className="d-inline-flex p-3">
              <div className="row">{educationsBuild}  </div>
            </div>
          </div>
        </div>
        <div style={{maxWidth:minWith,  minHeight: minHeight, minWidth: minWith, backgroundColor: "#b2adad"}}>

          <div className="" style={{paddingTop: "20px"}}>
            <figure className="text-center">
              <h3>Auswahl an Projekten</h3></figure>
            <div className="d-inline-flex p-3">
              <div className="row">{projectsBuild}  </div>
            </div>
          </div>
          <figure className="text-center">
            <button onClick={(event => ImpressumKontakt(event))} type="button" className="btn btn-dark">Kontakt
              & Impressum
            </button>
          </figure>
          <figure className="text-center">Ich nutze auf dieser Seite keine Cookies! </figure>
          <Ds/>
        </div>

      </div>

  );
}

export default App;
